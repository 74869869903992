import "@/styles/globals.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AppProps } from "next/app";
import localFont from "next/font/local";

import { usePageTracking } from "@/hooks/usePageTracking";

const digitalNumbersFont = localFont({ src: "./DigitalNumbers-Regular.ttf" });

const queryClient = new QueryClient();

export default function App({ Component, pageProps }: AppProps) {
  usePageTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <Component {...pageProps} />
    </QueryClientProvider>
  );
}
