import { useRouter } from "next/router";
import * as Fathom from "fathom-client";
import { useEffect } from "react";

export const usePageTracking = () => {
  const router = useRouter();

  useEffect(() => {
    if (!process.env.NEXT_PUBLIC_FATHOM_ID) {
      return;
    }

    Fathom.load(process.env.NEXT_PUBLIC_FATHOM_ID, {
      includedDomains: ["localhost:3000", "pentoshi.xyz", "pentoshicontrol.center"],
    });

    const onRouteChangeComplete = () => {
      Fathom.trackPageview();
    };

    router.events.on("routeChangeComplete", onRouteChangeComplete);

    return () => {
      router.events.off("routeChangeComplete", onRouteChangeComplete);
    };
  }, []);
};
